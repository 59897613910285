jQuery( window ).scroll( function() {
  fixedHeader();
});

jQuery( window ).resize( function() {
  fixedHeader();
});

jQuery( document ).ready( function() {
  fixedHeader();
});

function fixedHeader() {
  if ( jQuery( window ).scrollTop() > 150 ) {
    jQuery('#header').addClass('prepare');
  } else {
    jQuery('#header').removeClass('prepare');
  }

  if ( jQuery( window ).scrollTop() > 300 ) {
    jQuery('#header').addClass('fixed');
  } else {
    jQuery('#header').removeClass('fixed');
  }
}