function toggleMobileNav() {
  jQuery('html').toggleClass('locked');
  jQuery('body').toggleClass('locked');
  jQuery('#toggle-mobile-nav').toggleClass('open');
  jQuery('#mobile-nav').toggleClass('open');
  jQuery('#header').toggleClass('mobile-nav-open');
}

jQuery('.menu-item-has-children').keyup(function(event) {
  if (event.keyCode === 13) { // 13 => Enter
    jQuery(this).click();
  }
});

jQuery('.menu-item-toggle').click(function(e){
  e.preventDefault();
  jQuery(this).toggleClass('open');
  jQuery(this).parent().next().toggleClass('open');
});
