function toggleTapahtuma(ele) {
  if ( jQuery(ele).parent().parent().hasClass('open') ) {
    jQuery(ele).parent().next().css('height', '0px');
    jQuery(ele).html('Lue lisää ▾');
  } else {
    jQuery(ele).parent().next().css('height', jQuery(ele).parent().next().children().first().outerHeight(true) + 'px');
    jQuery(ele).html('Sulje ▵');
  }
  jQuery(ele).parent().parent().toggleClass('open');
}
